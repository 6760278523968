import styled, { DefaultTheme, StyledComponent } from 'styled-components';

import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import colors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';

import Styled from './Button.style';
import { IButtonProps } from './types';

const IcoSquare = styled.span`
	display: flex;
	align-items: center;
	padding: 0.5rem;
	background-color: ${colors.mist};
	border-radius: ${borderRadius.s};
	transition: background-color 0.2s ease-in-out;

	svg path {
		transition: fill 0.2s ease-in-out;
	}
`;

/**
 * Button Component
 * @param {JSX.Element} Component
 * @param {IButtonProps} props
 * @returns {JSX.Element}
 */
const ButtonMapping = (Component: StyledComponent<'button', DefaultTheme, IButtonProps, never>, props: IButtonProps) => {
	const { children, icon, isLoading, iconRight, success, ariaLabel, ...rest } = props;

	const isSpinnerInverted = () => {
		switch (Component) {
			case Styled.BlueButton:
			case Styled.PrimaryButton:
			case Styled.Button:
				return true;
			default:
				return false;
		}
	};

	const isAddButton = () => {
		if (Component === Styled.HoverButton && props.useAddIcon) {
			return true;
		}
		return false;
	};

	// Determine if this is an icon-only button
	const isIconOnly = icon && !children;

	const button = (
		<Component
			data-testid={props.testId}
			ref={props.btnRef}
			aria-label={ariaLabel || (isIconOnly ? `Button with ${icon} icon` : undefined)}
			aria-busy={isLoading}
			aria-disabled={props.disabled}
			{...rest}
		>
			{isAddButton() ? (
				<IcoSquare>
					<Icon name='plus' size='12' aria-hidden='true' />
				</IcoSquare>
			) : (
				icon && !iconRight && <Icon name={icon} size='14' aria-hidden='true' />
			)}{' '}
			{children}
			{isLoading && <LoadingSpinner inverted={isSpinnerInverted()} size='sm' data-testid='loading-in-button' aria-label='Loading' />}
			{iconRight && <Icon name={icon} size='14' aria-hidden='true' />}
			{success && !isLoading && <Icon name='checkmark' className='green' size='14' aria-hidden='true' />}
		</Component>
	);
	return button;
};

export const Button = (props: IButtonProps) => ButtonMapping(Styled.Button, props);

export const PrimaryButton = (props: IButtonProps) => ButtonMapping(Styled.PrimaryButton, props);

export const SecondaryButton = (props: IButtonProps) => ButtonMapping(Styled.SecondaryButton, props);

export const TertiaryButton = (props: IButtonProps) => ButtonMapping(Styled.TertiaryButton, props);

export const LinkButton = (props: IButtonProps) => ButtonMapping(Styled.LinkButton, props);

export const BlueButton = (props: IButtonProps) => ButtonMapping(Styled.BlueButton, props);

export const HoverButton = (props: IButtonProps) => ButtonMapping(Styled.HoverButton, props);
