import { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import InviteUsed from 'components/InviteUsed/InviteUsed';
import LoadingSpinner from 'components/LoadingSpinner';
import { Campaign, CampaignInstagramOwner } from 'components/NewBriefPage/types';
import CampaignService from 'services/Statistics/Campaign';
import toast from 'services/Toast';
import { isInfluencer } from 'services/auth-service';
import BriefPage from 'views/BriefPage';
import { getProperDataFromResponse } from 'views/influencer/InvitationBriefPage/helper';

const LandingPageByInvite = () => {
	const [loading, setLoading] = useState(true);
	const [linkAlreadyUsed, setLinkAlreadyUsed] = useState(false);
	const [campaign, setCampaign] = useState<Campaign>();
	const [campaignInstagramOwner, setCampaignInstagramOwner] = useState<CampaignInstagramOwner>();

	const params = useParams();

	const refreshCampaign = async () => {
		setLoading(true);
		try {
			if (params.influencerId && params.campaignId) {
				const res = await CampaignService.fetchBriefData(params.campaignId, params.influencerId);
				const newResult = getProperDataFromResponse(res);
				setCampaign(newResult.campaign);
				setCampaignInstagramOwner(newResult.campaignInstagramOwner as CampaignInstagramOwner);
			} else if (params.inviteToken) {
				const res = await CampaignService.fetchBriefData('', '', params.inviteToken);
				if (res) {
					const newResult = getProperDataFromResponse(res);
					setCampaign(newResult.campaign);
					setCampaignInstagramOwner(newResult.campaignInstagramOwner as CampaignInstagramOwner);
				}
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			if (err.response && (err.response.status === 410 || err.response.status === 404)) {
				toast.error(err.response.data.errors[0].title);
				setLinkAlreadyUsed(true);
			} else {
				console.error(err);
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		refreshCampaign();

		return () => {
			setLinkAlreadyUsed(false);
			setLoading(false);
			setCampaign(undefined);
			setCampaignInstagramOwner(undefined);
		};
	}, []);

	if (isInfluencer()) {
		return <Navigate to={`/influencer/dashboard`} />;
	}

	return (
		<>
			{loading ? (
				<div className='loading-center'>
					<LoadingSpinner position='center' />
				</div>
			) : linkAlreadyUsed ? (
				<InviteUsed />
			) : !campaign || !campaignInstagramOwner ? (
				<Navigate to='/not-found' />
			) : (
				<BriefPage onInvite campaign={campaign} campaignInstagramOwner={campaignInstagramOwner} refreshCampaign={refreshCampaign} />
			)}
		</>
	);
};

export default LandingPageByInvite;
