import { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';

import LoadingSpinner from 'components/LoadingSpinner';
import { Campaign, CampaignInstagramOwner } from 'components/NewBriefPage/types';
import CampaignService from 'services/Statistics/Campaign';
import BriefPage from 'views/BriefPage';

import { getProperDataFromResponse } from './helper';

/**
 * @returns {JSX.Element}
 */
const InvitationBriefPage = (): JSX.Element => {
	const [isLoading, setIsLoading] = useState(true);
	const [campaign, setCampaign] = useState<Campaign | undefined>(undefined);
	const [campaignInstagramOwner, setCampaignInstagramOwner] = useState<CampaignInstagramOwner | undefined>(undefined);

	const { campaignId, influencerId } = useParams();

	useEffect(() => {
		refreshCampaign();

		return () => {
			setCampaign(undefined);
			setCampaignInstagramOwner(undefined);
			setIsLoading(false);
		};
	}, [campaignId]);

	const refreshCampaign = async () => {
		if (influencerId && campaignId) {
			try {
				setIsLoading(true);
				const res = await CampaignService.fetchBriefData(campaignId, influencerId);
				const data = getProperDataFromResponse(res);
				setCampaign(data.campaign);
				setCampaignInstagramOwner(data.campaignInstagramOwner);
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		}
	};

	return (
		<div>
			{isLoading ? (
				<div className='loading-center'>
					<LoadingSpinner position='center' />
				</div>
			) : !campaign ? (
				<Navigate to='/not-found' />
			) : (
				campaignInstagramOwner && <BriefPage campaign={campaign} campaignInstagramOwner={campaignInstagramOwner} refreshCampaign={refreshCampaign} />
			)}
		</div>
	);
};

export default InvitationBriefPage;
