import axios from 'axios';
import { useState } from 'react';

import { SecondaryButton } from 'components/Button';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import toast from 'services/Toast';
import { createClient } from 'shared/ApiClient/ApiClient';
import colors from 'styles/theme/colors';

import Styled from './DeclineButton.style';

type DeclineButtonProps = {
	className?: string;
	link: string;
	refresh: () => void;
};
/**
 * @returns JSX.Element
 */
const DeclineButton = ({ className, link, refresh }: DeclineButtonProps): JSX.Element => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const declineInvite = async (link: string) => {
		const Client = createClient();
		try {
			return await Client.post(link);
		} catch (err) {
			if (axios.isAxiosError(err) && err.response?.data?.errors) {
				throw new Error(err.response.data.errors[0].source.message);
			}
			throw new Error(getSomethingWentWrongMessage());
		}
	};

	const handleDecline = async () => {
		setIsLoading(true);
		try {
			await declineInvite(link);
			refresh && refresh();
		} catch (error) {
			toast.error(error instanceof Error ? error.message : getSomethingWentWrongMessage());
		} finally {
			setIsLoading(false);
			setIsModalOpen(false);
		}
	};

	return (
		<>
			<SecondaryButton onClick={() => setIsModalOpen(true)} className={className} testId='decline-button' isLoading={isLoading}>
				Decline
			</SecondaryButton>

			<ConfirmModal
				title='Decline invite'
				buttonText='Decline invite'
				icon='mail'
				testId='decline-modal'
				IconBackgroundColor={colors.rose}
				action={handleDecline}
				isModalOpen={isModalOpen}
				toggleModal={() => setIsModalOpen(!isModalOpen)}
				isFetching={isLoading}
			>
				<>
					<p>Declining this invite will notify the campaign manager that you are not available to participate at this time.</p>
					<Styled.ModalTextWrapper></Styled.ModalTextWrapper>
				</>
			</ConfirmModal>
		</>
	);
};

export default DeclineButton;
